// White / grey(s)
$color_white: #FFFFFF;
$color_greyExtraLight-1: #FBFCFD; // uniquement pour le bg even des tableaux
$color_greyExtraLight-2: #F4F5F7;
$color_greyExtraLight-3: #dcddde;
$color_greyLight-1: #D2D4DB;
$color_greyLight-2: #B7B8BC;
$color_greyLight-3: #B3B6C3;
$color_grey-1: #85878E;
$color_greyJira: #DFE1E6;

// Blue / Purple / green
$color_blueExtraLight: #F3F7FA;
$color_table_blue: #eef0f3;
$color_blueGrey: #5A6381;
$color_blue: #0F56C9;
$color_blueNavy-1: #333C5A;
$color_blueNavy-2: #1f294e; // ITSF blue
$color_blueLight: #E9F7FF;

$color_blueJira: #4aaee8;
$color_blueJiraNavy: #0052CC;
$color_purpleJira: #904ee2;
$color_greenJiraStory: #63ba3c;
$color_greenJiraContingency: rgb(48, 95, 27);
$color_greenJira: #13865B;
$color_redJira: rgb(229, 73, 58);

$color_green_suivi: #089574;
$color_green_positive_value: #14b258;
$color_turquoise: #00C1B2;

// Yellow / Orange / Red
$color_yellow-light: rgba(250, 190, 42, 0.15);
$color_orange-1: #FABE2A;

$color_orange-2: #F28A16; // ITSF orange

$color_red-light: rgba(232, 23, 67, 0.15);
$color_red: #E81743;
$color_red_negative_value: #bf110b;

// Material design palette for Angular Material
// http://mcg.mbitson.com/#!?otepalette=%23b37154&themename=mcgtheme
$md-palette: (
	50 : #e4e5ea,
	100 : #bcbfca,
	200 : #8f94a7,
	300 : #626983,
	400 : #414969,
	500 : #1f294e,
	600 : #1b2447,
	700 : #171f3d,
	800 : #121935,
	900 : #0a0f25,
	A100 : #637aff,
	A200 : #304fff,
	A400 : #0026fc,
	A700 : #0022e2,
	contrast: (
		50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #ffffff,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #000000,
		A200 : #ffffff,
		A400 : #ffffff,
		A700 : #ffffff,
	)
);

$md-accent: (
	50 : #fdf1e3,
	100 : #fbdcb9,
	200 : #f9c58b,
	300 : #f6ad5c,
	400 : #f49c39,
	500 : #f28a16,
	600 : #f08213,
	700 : #ee7710,
	800 : #ec6d0c,
	900 : #e85a06,
	A100 : #ffffff,
	A200 : #ffe8dd,
	A400 : #ffc5aa,
	A700 : #ffb491,
	contrast: (
		50 : #1f294e,
		100 : #1f294e,
		200 : #1f294e,
		300 : #ffffff,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #1f294e,
		A200 : #1f294e,
		A400 : #1f294e,
		A700 : #1f294e,
	)
);


body {
	input {
		text-align: inherit;
	}

	.mat-form-field-appearance-fill {
		&.status-filter {
			width: 125px;
		}

		.mat-mdc-form-field-focus-overlay {
			display: none;
		}

		.mdc-text-field {
			padding: 0 5px !important;
		}

		.mdc-text-field--filled {
			background: transparent;
		}

		.mat-mdc-select-arrow-wrapper {
			transform: none;
		}
	}

	.mat-mdc-option {
		padding: 0 .5rem;

		.mdc-list-item__primary-text {
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.mat-mdc-form-field-infix {
		min-height: 40px;
		width: 200px;

		.mdc-floating-label--float-above {
			transform: translateY(-125%) scale(0.75);
		}
	}

	.large-select .mat-mdc-form-field-infix {
		width: 300px;
	}

	.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix,
	.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
		padding-bottom: 0;
		padding-top: 18px;
	}

	.mat-mdc-form-field-icon-suffix, .mat-mdc-form-field-icon-prefix {
		padding-top: 18px;
	}

	.clear {
		border: none;
		border-radius: 50%;
		background-color: $color_greyLight-1;
		padding: 3px;
		font-size: .8rem;
	}

	// checkbox
	.mat-mdc-checkbox {
		.mdc-checkbox {
			flex: 0 0 20px;
			width: 20px;
			height: 20px;
			--mdc-checkbox-state-layer-size: 20px;

			.mat-mdc-checkbox-touch-target {
				width: calc(var(--mdc-checkbox-state-layer-size) + 3px);
				height: calc(var(--mdc-checkbox-state-layer-size) + 3px);
			}

			.mdc-checkbox__background {
				width: 12px;
				height: 12px;
				border-width: 1px;
				top: calc((var(--mdc-checkbox-state-layer-size) - 10px) / 2);
				left: calc((var(--mdc-checkbox-state-layer-size) - 10px) / 2);
			}
		}
	}

	.mdc-button{
		--mdc-typography-button-letter-spacing: normal;
	}


	.mat-mdc-icon-button{
		--mdc-icon-button-icon-size: 18px;
	}

	.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text{
		--mat-menu-item-label-text-size: 14px;
	}

	.mat-datepicker-toggle {
		.mat-mdc-icon-button.mat-mdc-button-base {
			--mdc-icon-button-state-layer-size: 24px;
			padding: 0;
		}
		.mat-mdc-icon-button .mat-mdc-button-touch-target {
			width: 24px;
			height: 24px;
		}
	}

	// options
	.small-option {
		font-size: .8rem;
		min-height: 30px;
	}
}

button {
	outline: none !important;

	&.mat-mdc-button.mat-mdc-button-base,
	&.mat-mdc-raised-button.mat-mdc-button-base,
	&.mat-mdc-unelevated-button.mat-mdc-button-base,
	&.mat-mdc-outlined-button.mat-mdc-button-base {
		height: 30px;
	}

	&.mat-mdc-icon-button .mat-mdc-button-touch-target {
		height: calc(var(--mdc-icon-button-state-layer-size) + 3px);
		width: calc(var(--mdc-icon-button-state-layer-size) + 3px);
	}

	&.its-button {
		border-radius: 15px;
		background: linear-gradient($color_red, $color_orange-2);
		font-size: 12px;
		height: 30px;
		line-height: 30px;
		--mdc-filled-button-label-text-color: #{$color_white} !important;
		--mdc-protected-button-label-text-color: #{$color_white} !important;

		.mat-mdc-button-touch-target {
			height: 30px;
		}
	}

	&.its-white-button {
		border-radius: 15px;
		background: $color_white;
		color: $color_blueNavy-2;
		font-size: 12px;
		height: 30px;
		line-height: 30px;
		//width: 83px;
	}

	&.round-button {
		width: 30px;
		height: 30px;
		line-height: 30px;
		box-shadow: none !important;
	}
}

.mat-mini-fab .mat-button-wrapper {
	padding: 0 !important;
}

.mat-mdc-select-value-text {
	color: $color_grey-1;
}

mat-label {
	color: $color_blueNavy-2;
}

input.circular-input {
	border-radius: 14px;
	border: 1px solid $color_greyExtraLight-3;
	color: $color_grey-1;
	width: 220px;
	padding-left: 10px;
	font-size: 14px;
	margin-left: 40px;
	margin-right: 34px;
	margin-top: 20px;
	height: 26px;

	&::placeholder {
		font-style: italic;
		font-size: 14px;
		color: $color_greyLight-2;
	}
}

.actions {
	position: relative;
	margin-right: 1.5rem;
	display: flex;
	align-items: center;
	margin-left: auto;
	gap: 1rem;
}

@media screen and (min-width: 1525px) and (max-width: 1625px) {
	.actions {
		max-width: 230px;
		text-align: center;
	}
}

.checkbox {
	margin-top: 20px;
	margin-right: 39px;
	color: $color_blueNavy-2;
}

button.add-task {
	width: 17px;
	height: 17px;
	line-height: 15px;
	box-shadow: none !important;

	mat-icon {
		width: 17px;
		height: 17px;
		vertical-align: top !important;
		font-size: 17px;
	}
}

.more-button {
	width: 30px !important;
	height: 16px !important;
	line-height: 16px !important;
	border-radius: 50px !important;
	background-color: white;
	padding: 0 !important;

	mat-icon {
		line-height: 16px !important;
	}
}

.EPIC .more-button,
.STORY .more-button,
.SUBTASK .more-button {
	background-color: $color_greyLight-3;
}

.col-informations button.fold-button {
	background: none;
	border: none;
	box-shadow: none;
	width: 30px;
}

.EPIC .col-informations button.fold-button {
	color: $color_grey-1;
	background-color: $color_greyLight-1;
}

.table-checkbox label {
	margin: 0;
}

.floating-label {
	position: absolute;
	top: 0;
	font-size: $td_font_size;
	color: $color_blueNavy-2;
	line-height: 1;
	font-weight: bold;
	z-index: 1;
	display: flex;
	align-items: center;
}

mat-option.mat-mdc-option {
	font-family: 'Lato', sans-serif;
}

@import "src/assets/variables/variables";

.avatar {
	border-radius: 50%;
	height: 40px;
	width: 40px;
	margin-left: 3px;
	margin-right: 5px;
}

.logo {
	background: $color_white;
	box-shadow: 0 2px 6px 0 rgba($color_blueNavy-2, .4);
	height: $th_header_logo_size;
	width: $th_header_logo_size;
	border-radius: 3px;
	margin-left: 3px;
	margin-right: 5px;
	object-fit: contain;
}

.project {
	background: $color_white;
	box-shadow: 0 2px 6px 0 rgba($color_blueNavy-2, .4);
	height: 90px;
	width: 90px;
	border-radius: 3px;
	margin-left: 3px;
	margin-right: 5px;
}

.av-project-logo {
	background: $color_white;
	box-shadow: 0 2px 6px 0 rgba($color_blueNavy-2, .4);
	height: 64px;
	width: 64px;
	border-radius: 3px;
	margin-left: 3px;
	margin-right: 5px;
}

@import "src/assets/variables/variables";

.table-container {
	margin: 10px;
}

.rotate-180 {
	transform: rotate(180deg);
}
.rotate-90 {
	transform: rotate(90deg);
}

.element-detail {
	overflow: hidden;
	display: flex;
}


tr.detail-row {
	height: 0 !important;

	td {
		border-bottom: 0;
	}
}

.border-dark-grey {
	border-left: 1px solid $color_greyExtraLight-3;
	border-right: 1px solid $color_greyExtraLight-3;
}

.arrow-modification {
	font-size: 6px;
}

.pen-modification {
	font-size: 20px;
}

.up-arrow {
	font-size: 8px;
	padding-left: 12px;
	padding-right: 12px;
	transition: transform 300ms;
}

.no-padding td {
	padding: 0 !important;
}

tr {
	height: $tr_height;
}

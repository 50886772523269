@use '@angular/material' as mat;
@import '@angular/material/theming';
// Plus imports for other components in your app.
@import "src/assets/variables/colors";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$palette: mat.define-palette($md-palette);
$accent: mat.define-palette($md-accent);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.define-light-theme($palette, $accent);

/*$hover: map-get($ote-theme, foreground);
$hover: map_merge($hover, (hover: #FF00FF));
$ote-theme: map_merge($ote-theme, (background: $hover));*/

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

@import "src/assets/variables/variables";


.tag-NEW, .tag-TO_EVALUATE, .tag-BACKLOG, .tag-BLOCKED, .tag-SUSPENDED, .tag-REOPENED, .tag-ACKNOWLEDGED,
.tag-10000, .tag-10036, .tag-1, .tag-10004, .tag-10037, .tag-4, .tag-10050 {
	background-color: white;
	color: black;
	font-weight: bold;
	font-size: 12px;
	padding: 1px 3px;
	border-radius: 2px;
}

.tag-IN_PROGRESS, .tag-EN_COURS, .tag-ANTICIPATED, .tag-TO_REVIEW,
.tag-3, .tag-10038, .tag-10011 {
	background-color: $color_blueJiraNavy;
	color: white;
	font-weight: bold;
	font-size: 12px;
	padding: 1px 3px;
	border-radius: 2px;
}

.tag-DONE_DEV, .tag-PACKAGED, .tag-DELIVERED_RECETTE, .tag-VALIDATED_RECETTE, .tag-DELIVERED_PROD, .tag-VALIDATED_PROD, .tag-CLOSED, .tag-FERMÉE, .tag-IN_STORY,
.tag-10039, .tag-10040, .tag-10041, .tag-10042, .tag-10043, .tag-10044, .tag-6, .tag-10051 {
	background-color: $color_greenJira;
	color: white;
	font-weight: bold;
	font-size: 12px;
	padding: 1px 3px;
	border-radius: 2px;
}

.priority-LOWEST {
	color: $color_greenJiraStory;
}

.priority-LOW {
	color: $color_greenJiraContingency;
}

.priority-MEDIUM {
	color: $color_orange-2;
}

.priority-HIGH {
	color: $color_red;
}

.priority-HIGHEST {
	color: $color_red_negative_value;
}

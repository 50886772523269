$icomoon-font-family: "suivi_projets" !default;
$icomoon-font-path: "/assets/fonts" !default;

$sp-edit: "\e900";
$sp-delete: "\e901";
$sp-pen: "\e902";
$sp-speech-bubble: "\e903";
$sp-up-arrow: "\e904";
$sp-right-arrow: "\e905";
$sp-triangle: "\e906";

$sp-EPIC-path1: "\e907";
$sp-EPIC-path2: "\e908";
$sp-STORY-path1: "\e909";
$sp-STORY-path2: "\e90a";
$sp-STORY-CONTINGENCY-path1: "\e90b";
$sp-STORY-CONTINGENCY-path2: "\e90c";
$sp-SUBTASK-path1: "\e90d";
$sp-SUBTASK-path2: "\e90e";
$sp-STORY-INT-path1: "\e90f";
$sp-STORY-INT-path2: "\e910";
$sp-SUBTASK-INT-path1: "\e911";
$sp-SUBTASK-INT-path2: "\e912";
$sp-SUBTASK-SUBBUG-path1: "\e913";
$sp-SUBTASK-SUBBUG-path2: "\e914";
$sp-SUBTASK-SUBBUG-INT-path1: "\e915";
$sp-SUBTASK-SUBBUG-INT-path2: "\e916";
$sp-locked: "\e917";
$sp-draft: "\e918";
$sp-finish: "\e919";
$sp-av-optionnel-oui: "\e91a";
$sp-av-optionnel-non: "\e91b";
$sp-stats-bars: "\e91c";
$sp-refresh: "\e91d";

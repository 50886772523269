button.caret-down-btn {
	width: 1.2rem !important;
	height: 1.2rem !important;
	line-height: 1.2rem !important;
	padding: 0 !important;

	mat-icon {
		width: 1.2rem !important;
		height: 1.2rem !important;
		line-height: 1.2rem !important;
		font-size: 1.2rem !important;
		vertical-align: top !important;
	}
}

@import "src/assets/variables/variables";

.mat-snack-bar-container.error {
	font-weight: bold;
	background: $color_red_negative_value;
}

.mat-snack-bar-container.success {
	font-weight: bold;
	background: $color_green_positive_value;
}

.mat-snack-bar-container.warning {
	font-weight: bold;
	background: $color_orange-2;
}


.horizontal-scroll-chips {
	div.mat-chip-list-wrapper {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;

		.mat-chip {
			flex: 0 0 auto;
		}
	}
}

.mat-chip {
	padding: 0 8px 0 12px !important;
	min-height: 20px !important;
}

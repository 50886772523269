@import "src/assets/variables/variables";
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,900');

a {
	outline: none;

	&:not(:hover) {
		text-decoration: none;
		color: $color_blueGrey;
	}
}

*:not(i) {
	font-family: 'Lato', sans-serif;
}

.mat-mdc-option .mdc-list-item__primary-text {
	font-family: 'Lato', sans-serif;
}

i {
	font-style: normal;
}

label {
	margin-bottom: 0;
}

.font-size-12 {
	font-size: 12px;
}

.font-size-14 {
	font-size: 14px;
}

.font-size-16 {
	font-size: 16px;
}

.text-vertical {
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	display: flex;
	align-items: center;
	min-width: 1rem;
	width: 100%;
	line-height: 1;
	font-family: 'Roboto', 'Lato', sans-serif;
}

.title {
	text-align: left;
	color: $color_orange-2;
	font-size: 23px;
	line-height: 28px;
	font-weight: bold;
	margin-right: 40px;
}

.offer-parameter {
	font-size: 12px;
	margin-right: 20px;
}

.cursor-pointer {
	cursor: pointer;
}

// Colors
.color-white {
	color: $color-white !important;
}

.color-grey-light-1 {
	color: $color_greyLight-1;
}

.color-grey-light-2 {
	color: $color_greyLight-2;
}

.color-grey-light-3 {
	color: $color_greyLight-3;
}

.color-grey {
	color: $color_grey-1;
}

.color-blue-grey {
	color: $color_blueGrey
}

.color-table-blue {
	color: $color_table_blue
}

.color-blue {
	color: $color_blue;
}

.color-blue-navy-1 {
	color: $color_blueNavy-1;
}

.color-blue-navy-2 {
	color: $color_blueNavy-2;
}

.color-yellow-light {
	color: $color_yellow-light;
}

.color-orange-1 {
	color: $color_orange-1;
}

.color-orange-2 {
	color: $color_orange-2;
}

.color-red {
	color: $color_red;
}

.color-jira-EPIC {
	color: $color_purpleJira;
}

.color-jira-STORY, .color-jira-STORY-REGULAR, .color-jira-STORY-INT {
	color: $color_greenJiraStory;
}

.color-jira-STORY-CONTINGENCY {
	color: $color_greenJiraContingency;
}

.color-jira-SUBTASK, .color-jira-SUBTASK-REGULAR, .color-jira-SUBTASK-INT {
	color: $color_blueJira;
}

.color-jira-SUBTASK-SUBBUG, .color-jira-SUBTASK-SUBBUG_INT {
	color: $color_redJira;
}

.color-green-suivi {
	color: $color_green_suivi;
}

.color-turquoise {
	color: $color_turquoise;
}

// Background
.bg-grey-extralight-1 {
	background-color: $color_greyExtraLight-1 !important;
}

.bg-grey-extralight-2 {
	background-color: $color_greyExtraLight-2 !important;
}

.bg-grey-extralight-3 {
	background-color: $color_greyExtraLight-3 !important;
}

.bg-grey-light-1 {
	background-color: $color_greyLight-1 !important;
}

.bg-grey-light-2 {
	background-color: $color_greyLight-2 !important;
}

.bg-grey-light-3 {
	background-color: $color_greyLight-3 !important;
}

.bg-grey {
	background-color: $color_grey-1 !important;
}

.bg-blue-extralight {
	background-color: $color_blueExtraLight !important;
}

.bg-blue-grey {
	background-color: $color_blueGrey !important;
}

.bg-table_blue {
	background-color: $color_table_blue !important;
}

.bg-blue-navy-1 {
	background-color: $color_blueNavy-1 !important;
}

.bg-blue-navy-2 {
	background-color: $color_blueNavy-2 !important;
}

.bg-yellow-light {
	background-color: $color_yellow-light !important;
}

.bg-orange-1 {
	background-color: $color_orange-1 !important;
}

.bg-orange-2 {
	background-color: $color_orange-2 !important;
}

.bg-red-light {
	background-color: $color_red-light !important;
}

.bg-red {
	background-color: $color_red !important;
}

.bg-green-suivi {
	background-color: $color_green_suivi !important;
}

@import "src/assets/variables/colors";

// Context menu
.itsf-override {
	min-height: 20px !important;

	.mat-mdc-menu-content {
		padding: 0 !important;
	}

	button {
		padding: 0 .5rem !important;
		line-height: 20px !important;
		min-height: 30px !important;

		--mdc-typography-body1-font-size: .8rem;
	}
}

// Lot menu
.mat-menu-override-description {
	&.mat-mdc-menu-panel {
		max-width: 350px
	}

	.mat-mdc-menu-item{
		min-height: 48px;
		height: fit-content;
		max-height: 96px;
		line-height: 16px;
		padding: 0 8px;
		white-space: normal;
		text-overflow: initial;
		overflow: auto;
	}

	.description {
		font-size: 12px;
		color: $color_grey-1;
		line-height: 12px;
		margin: 4px 0;
	}
}

// Menu size-limit override
.menu-size-limit-override {
	max-width: none !important;
	max-height: none !important;
}

// Checkbox vertical align fix
.mat-checkbox.table-checkbox label .mat-checkbox-inner-container{
  height: 14px;
}

.side-menu .mat-drawer-inner-container{
	display: flex;
	flex-direction: column;
}

:root {
	--mdc-checkbox-selected-checkmark-color: #ffffff;
}


/* You can add global styles to this file, and also import other style files */

@import "bootstrap/scss/bootstrap";
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';
@import "assets/variables/variables";
@import "assets/styles/angular-material-theme";
@import "assets/styles/suivi_projets";

html, body { height: 100%; }
body { margin: 0; }

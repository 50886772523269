
[class^="sp-"], [class*=" sp-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sp-locked {
	&:before {
		content: $sp-locked;
	}
}

.sp-delete {
  &:before {
	content: $sp-delete;
  }
}
.sp-edit {
  &:before {
	content: $sp-edit;
  }
}

.sp-EPIC .path1 {
  &:before {
	content: $sp-EPIC-path1;
	color: $color_purpleJira;
  }
}
.sp-EPIC .path2 {
  &:before {
	content: $sp-EPIC-path2;
	margin-left: -1em;
	color: rgb(255, 255, 255);
  }
}
.sp-STORY .path1, .sp-STORY-REGULAR .path1 {
  &:before {
	content: $sp-STORY-path1;
	color: $color_greenJiraStory;
  }
}
.sp-STORY .path2, .sp-STORY-REGULAR .path2 {
  &:before {
	content: $sp-STORY-path2;
	margin-left: -1em;
	color: rgb(255, 255, 255);
  }
}
.sp-STORY-CONTINGENCY .path1 {
  &:before {
	content: $sp-STORY-CONTINGENCY-path1;
	color: $color_greenJiraContingency;
  }
}
.sp-STORY-CONTINGENCY .path2 {
  &:before {
	content: $sp-STORY-CONTINGENCY-path2;
	margin-left: -1em;
	color: rgb(255, 255, 255);
  }
}
.sp-STORY-INT .path1:before {
	content: $sp-STORY-INT-path1;
	color: rgb(255, 255, 255);
}
.sp-STORY-INT .path2:before {
	content: $sp-STORY-INT-path2;
	margin-left: -1em;
	color: $color_greenJiraStory;
}
.sp-SUBTASK .path1, .sp-SUBTASK-REGULAR .path1 {
	&:before {
		content: $sp-SUBTASK-path1;
		color: $color_blueJira;
	}
}
.sp-SUBTASK .path2, .sp-SUBTASK-REGULAR .path2 {
	&:before {
		content: $sp-SUBTASK-path2;
		margin-left: -1em;
		color: rgb(255, 255, 255);
	}
}
.sp-SUBTASK-INT .path1:before {
	content: $sp-SUBTASK-INT-path1;
	color: rgb(255, 255, 255);
}
.sp-SUBTASK-INT .path2:before {
	content: $sp-SUBTASK-INT-path2;
	margin-left: -1em;
	color: $color_blueJira;
}
.sp-SUBTASK-SUBBUG .path1:before {
	content: $sp-SUBTASK-SUBBUG-path1;
	color: $color_redJira;
}
.sp-SUBTASK-SUBBUG .path2:before {
	content: $sp-SUBTASK-SUBBUG-path2;
	margin-left: -1em;
	color: rgb(255, 255, 255);
}
.sp-SUBTASK-SUBBUG_INT .path1:before {
	content: $sp-SUBTASK-SUBBUG-INT-path1;
	color: rgb(255, 255, 255);
}
.sp-SUBTASK-SUBBUG_INT .path2:before {
	content: $sp-SUBTASK-SUBBUG-INT-path2;
	margin-left: -1em;
	color: $color_redJira;
}
.sp-right-arrow {
  &:before {
	content: $sp-right-arrow;
  }
}
.sp-pen, .sp-SIGNED {
  &:before {
	content: $sp-pen;
  }
}

.sp-DRAFT {
	&:before {
		content: $sp-draft;
	}
}

.sp-FINISH {
	&:before {
		content: $sp-finish;
	}
}

.sp-up-arrow {
  &:before {
	content: $sp-up-arrow;
  }
}
.sp-iconmonstr-triangle-1 {
  &:before {
	content: $sp-triangle;
  }
}
.sp-speech-bubble, .sp-OPEN {
  &:before {
	content: $sp-speech-bubble;
  }
}

.sp-av-optionnel-oui {
	&:before {
		content: $sp-av-optionnel-oui;
		color: #4baee8;
	}
}

.sp-av-optionnel-non {
	&:before {
		content: $sp-av-optionnel-non;
		color: #0B9371;
	}
}

.sp-stats-bars {
	&:before {
		content: $sp-stats-bars;
	}
}
.sp-refresh-icon {
	&:before {
		content: $sp-refresh;
	}
}

// Dialog
.mdc-dialog {
	.mat-mdc-dialog-surface {
		padding: 0;
		overflow-y: visible;
		overflow-x: visible;
	}

	.mat-dialog-content {
		padding: 0;
		margin: 0;
	}

	.mat-mdc-dialog-actions {
		border-top: 1px solid $color_greyLight-3;

		.centered-actions {
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.title-padding {
	padding-top: 24px;
	padding-bottom: 24px;
}

.form-input-field {
	display: block !important;
}

.dialog-content {
	padding: 20px;
}

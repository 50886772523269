@import "../variables/icon";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?nbfybl');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?nbfybl#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?nbfybl') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?nbfybl') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?nbfybl##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}


